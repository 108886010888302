import React from "react"
import Layout from "../../components/layout"
import LegalSidebar from "../../components/Organisms/LegalSidebar/LegalSidebar"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

export default function TermsAndConditions({ data }) {
  debugger
  const {
    metaData,
    body,
    heading
  } = data.allContentfulZLegal.edges[0].node


  return (
    <Layout
      IsHeaderImageDark={true}>
        <head>
                <title>{metaData.title}</title>
                <meta charset="utf-8" />
                <meta name="description" content={metaData.description} />
                <meta name="keywords" content={metaData.keywords.keywords} />
                <meta name="robots" content="noindex" />
            </head>

      <section>
        <div className="sectionFit-container">
          <div className="row">
            <div className="col-lg-4 pl-0">
              <LegalSidebar />

            </div>

            <div className="col-lg-8">
              <div>
                <p className="p-space2">
                  {documentToReactComponents(body.json)}

                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  );
}





export const pageQuery = graphql`
query legalTermsConditionQuery($locale: String!){
  allContentfulZLegal(filter: {metaData: {slug: {eq: "terms-and-conditions"}}, node_locale: {eq: $locale}}) {
    edges {
      node {
        metaData {
          slug
          title
          description
          keywords {
              keywords
          }
      }
        heading
        body {
          body
          json
        }
      }
    }
    distinct(field: heading)
  }
}
`